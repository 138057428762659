// extracted by mini-css-extract-plugin
export var productDiscoverySession__card = "ds_Cc";
export var productDiscoverySession__cardFour = "ds_Ch";
export var productDiscoverySession__cardOne = "ds_Cd";
export var productDiscoverySession__cardThree = "ds_Cg";
export var productDiscoverySession__cardTwo = "ds_Cf";
export var productDiscoverySession__cardWrapper = "ds_Cb";
export var productDiscoverySession__description = "ds_Cl";
export var productDiscoverySession__header = "ds_B9";
export var productDiscoverySession__image = "ds_Cm";
export var productDiscoverySession__info = "ds_Cj";
export var productDiscoverySession__title = "ds_Ck";